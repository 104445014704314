var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"account-settings-layout"}},[_c('div',{staticClass:"account-settings-layout-container"},[_c('div',{staticClass:"account-settings-navbar p-2 card"},[_c('router-link',{staticClass:"navbar-item",class:{
          active: _vm.currentRoute.startsWith(
            '/settings/account-settings/change-password'
          )
        },attrs:{"to":"/settings/account-settings/change-password"}},[_vm._v(" "+_vm._s(_vm.$t('settings.accountSettings.changePassword.title'))+" ")]),_c('router-link',{staticClass:"navbar-item",class:{
          active: _vm.currentRoute.startsWith(
            '/settings/account-settings/update-organization-info'
          )
        },attrs:{"to":"/settings/account-settings/update-organization-info"}},[_vm._v(" "+_vm._s(_vm.$t('settings.accountSettings.updateOrganizationInfo.title'))+" ")])],1),_c('div',{staticClass:"flex-fill card px-3 py-3"},[_c('router-view')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }