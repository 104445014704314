<template>
  <div id="account-settings-layout">
    <div class="account-settings-layout-container">
      <div class="account-settings-navbar p-2 card">
        <router-link
          to="/settings/account-settings/change-password"
          class="navbar-item"
          :class="{
            active: currentRoute.startsWith(
              '/settings/account-settings/change-password'
            )
          }"
        >
          {{ $t('settings.accountSettings.changePassword.title') }}
        </router-link>
        <router-link
          to="/settings/account-settings/update-organization-info"
          class="navbar-item"
          :class="{
            active: currentRoute.startsWith(
              '/settings/account-settings/update-organization-info'
            )
          }"
        >
          {{ $t('settings.accountSettings.updateOrganizationInfo.title') }}
        </router-link>
      </div>
      <div class="flex-fill card px-3 py-3">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSettingsLayout',
  computed: {
    currentRoute() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.account-settings-navbar {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 0.5rem;
}
.account-settings-layout-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.navbar-item {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  &.active {
    font-weight: bold;
    background-color: #f0f0f0;
  }
}
</style>
